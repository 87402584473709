<template>

    <section>

      <!-- card collapsible  -->
      
        <b-card-actions  :title="connectionStatus" action-collapse>
            <b-card-text>
                <template v-if="!isUserConnected">
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="connectWeb3Modal">
                        Connect
                    </b-button>
                </template>
                <template v-else>
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="disconnectWeb3Modal">
                        Disconnect
                    </b-button>
                </template>
            </b-card-text>
        </b-card-actions>
      


        <b-tabs>
            <b-tab active>
                <template #title>
                    <feather-icon icon="HomeIcon" />
                    <span>Mint</span>
                </template>

                <VoucherMintView />

            </b-tab>

            <b-tab>
                <template #title>
                    <feather-icon icon="ToolIcon" />
                    <span>Update</span>
                </template>

                <VoucherUpdateView />

            </b-tab>

            <b-tab>
                <template #title>
                    <feather-icon icon="SettingsIcon" />
                    <span>Settings</span>
                </template>                


            </b-tab>

        </b-tabs>

        <div class="pt-2"></div>
        <VoucherDetailView />

    </section>

</template>

<script>

import { BTabs, BTab, BCardText, BButton } from 'bootstrap-vue'

import { mapActions, mapGetters } from 'vuex'

import Ripple from 'vue-ripple-directive'

import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

import VoucherMintView from './VoucherMint.vue'
import VoucherUpdateView from './VoucherUpdate.vue'
import VoucherDetailView from './VoucherDetail.vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    data: function() {
        return {
            connectionStatus: ""
        }
    },
    computed: {
        ...mapGetters("web3",["isUserConnected","getActiveAccount","getChainName","getChainId"]),
        ...mapGetters("JokerCharlieVoucher", ["getContractMessage", "getContract"] ),
    },
    methods: {
        ...mapActions("web3",["connectWeb3Modal","disconnectWeb3Modal","initWeb3Modal"]),
        ...mapActions("JokerCharlieVoucher", ["initContract"] ),
        ...mapActions("logs", ["logToast"]),
        updateConnectionStatus: async function() {
            await this.initContract()
            if (!this.isUserConnected) {
                this.connectionStatus = `Not connected to wallet; Please connected`
            } else {
                this.connectionStatus = `Connected to ${this.getChainName}[${this.getChainId}] ${this.getContractMessage}: ${this.getActiveAccount}`
            }                        
        },
        showToast: function(variant, title, message) {
            this.logToast(variant, title, message)
            this.$toast({
                component: ToastificationContent,
                props: { title: title, text: message, variant },
            })
        },       
    },
    mounted: function() {
        this.initWeb3Modal()
        this.updateConnectionStatus()        
    },
    components: {
        BCardText,
        BCardActions,
        BTabs,
        BTab,
        BButton,
        VoucherMintView,
        VoucherUpdateView,
        VoucherDetailView,
        ToastificationContent
    },
    watch: {
        isUserConnected : function() { this.updateConnectionStatus() },
        getActiveAccount: function() { this.updateConnectionStatus() },
        getChainId: function() { this.updateConnectionStatus() },
        getContract: function() { this.updateConnectionStatus() },
    },
    directives: {
        Ripple,
    },    
}
</script>